* {
  font-family: Rubik, sans-serif;  
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Bold.ttf') format('truetype');
    /* font-weight: 700; */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
