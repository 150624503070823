/* 
@keyframes floatAnimation {
  0% {
    transform: translateY(0) translateX(-50%) scaleY(-1) scale(2);
  }
  50% {
    transform: translateY(-20px) translateX(-50%) scaleY(-1) scale(2);
  }
  100% {
    transform: translateY(0) translateX(-50%) scaleY(-1) scale(2);
  }
} */

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0) translateX(-50%) scaleY(-1) scale(2.25);
    opacity: 1; /* fully visible */
  }
  50% {
    transform: translateY(-150%) translateX(-50%) scaleY(-1) scale(2.25);
    opacity: 1; /* fully transparent */
  }
}


/* Smaller viewports */
@media (max-height: 500px) {
  .floating-pointer {
    top: -12vh; /* Closer to the component on smaller screens */
  }
}
@media (max-height: 800px) {
  .floating-pointer {
    top: -8vh; /* Closer to the component on smaller screens */
  }
}
/* Larger viewports */
@media (min-height: 801px) {
  .floating-pointer {
    top: -5vh; /* Further from the component on larger screens */
  }
}

/* Floating pointer CSS */
.floating-pointer {
  position: absolute;
  left: 50%; /* Center the pointer horizontally */
  /* width: 10vw; */
  /* width: 100px; */
  transform: translateX(-50%) scaleY(-1) scale(1.5); /* Adjusts for centering and flipping */
  animation: floatAnimation 2s ease-in-out infinite;
  z-index: 100; /* Ensures the pointer is above the cards */
}


body {
    min-height: 500px;
    min-width: 500px;
    overflow-x: auto;
    overflow-y: auto;
}

span span span .MuiIconButton-label {
    color: #e9b699 !important;
    background: #7c4e4d !important; 
}

.MuiTable-root { 
    border-spacing: 0 1.5px !important;
    border-collapse: separate !important;
}

.span2 .MuiTable-root { 
    border-spacing: 0 5px !important;
    border-collapse: separate !important;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.MuiBox-root {
    pointer-events: all;
}

span2 .MuiIconButton-label {
    color: #000 !important;
}

/*.custom-cursor {*/
/*    cursor: url("../../../public/assets/ui/cursor.png"), pointer;*/
/*}*/

.MuiPaper-root.MuiDialog-paper {
    padding: 2px;
    background: transparent;
    box-shadow: none;
}

.div.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background: rgb(0,0,0,0) !important
}
.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    box-shadow: none;
}

.swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
    /* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
}
.swing img {
    border: 5px solid #f8f8f8;
    display: block;
}
.swing:after{
    content: '';
    position: absolute;  
    width: 20px; height: 20px;  
    border: 1px solid #999;
    top: -10px; left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
}
/* nail */
.swing:before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -14px;left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
}
 
@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}



/**
 * The "shine" element
 */

.shineIcon {
    /*cursor: url("../../../public/assets/ui/cursor_hover.png") 2 2, auto !important;*/
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.shineIcon:hover {
    cursor: url("../../../public/assets/ui/cursor_hover.png") 2 2, auto !important;
}

.shineIcon:after {
  
  animation: shine 3s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
    /* top: -70%;
  left: -100%;
  width: 100%;
  height: 100%; */
  opacity: 0;
  transform: rotate(30deg);
  
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

/* Hover state - trigger effect */


/* Active state */

.shineIcon:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    /* transition-duration: 0.7s, 0.7s, 0.15s; */
      transition-duration: 1s, 1s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  /* animation-name: pulse; */
  animation: pulse 3s ease-in-out infinite;
  animation-timing-function: ease-in-out;
}




::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: #fff1d2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}